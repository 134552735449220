import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import Text from '@components/Text'
import Picture from '@components/Picture'
import styles from './Innovators.module.scss'
import classnames from 'classnames/bind'
import GradientText from '@components/GradientText'
import Person from './Person'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const processRichTextNodes = nodes => {
  return nodes.map((node, index) => {
    switch (node.nodeType) {
      case 'text':
        if (node.marks && node.marks.some(mark => mark.type === 'bold')) {
          return (
            <Text tag="span" type="b1" style={{ fontWeight: 'bold' }}>
              {node.value}
            </Text>
          )
        } else {
          return (
            <Text tag="span" type="b1">
              {node.value}
            </Text>
          )
        }

      case 'paragraph':
        // Wrap the paragraph content and add a <br/> at the end of the paragraph
        return (
          <p key={index}>
            {processRichTextNodes(node.content)}
            <br />
            <br />
          </p>
        )

      case 'hyperlink':
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {processRichTextNodes(node.content)}
          </a>
        )

      // Handle other node types as needed...
      default:
        if (node.content && Array.isArray(node.content)) {
          return processRichTextNodes(node.content)
        }
        return null
    }
  })
}

const Innovators = ({
  list,
  subHeader,
  logos,
  focusArea1,
  focusArea2,
  focusArea3,
  contactEmail,
  showTeam,
  blogPosts,
}) => {
  var subHeaderArray, focusArea1Array, focusArea2Array, focusArea3Array
  if (subHeader) {
    subHeaderArray = subHeader.subHeader.split('|')
  }
  if (focusArea1) {
    focusArea1Array = focusArea1.focusArea1.split('|')
  }
  if (focusArea2) {
    focusArea2Array = focusArea2.focusArea2.split('|')
  }
  if (focusArea3) {
    focusArea3Array = focusArea3.focusArea3.split('|')
  }

  const [pageTitle, setPageTitle] = useState('GROUP1001 Innovations')
  const [pageDescription, setPageDescription] = useState('')
  const [pageImage, setPageImage] = useState(logos[0].file.url)

  var showFeatured = true
  const [featuredHidden, setFeaturedHidden] = useState(true)
  const [featuredBlogIndex, setFeaturedBlogIndex] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const scrollToRef = useRef(null)
  const handleClick = urlPath => {
    window.location.href = urlPath
    var pageHash = window.location.hash.replace('#', '')
    if (pageHash) {
      const { index } = getIndexAndTitleFromHash(pageHash)
      setFeaturedBlogIndex(index)
      setFeaturedHidden(false)
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const getIndexAndTitleFromHash = hash => {
    var sortedPosts = blogPosts.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
    const decodedHash = decodeURIComponent(hash)
    const matchingPostIndex = sortedPosts.findIndex(post => post.title === decodedHash)

    if (matchingPostIndex !== -1) {
      return {
        index: matchingPostIndex,
        title: sortedPosts[matchingPostIndex].title,
      }
    }

    return { index: null, title: '' }
  }

  useEffect(() => {
    let pageHash = ''

    if (typeof window !== `undefined` && window.location && window.location.hash) {
      pageHash = window.location.hash.replace('#', '')
      if (pageHash) {
        const { index } = getIndexAndTitleFromHash(pageHash)
        setFeaturedBlogIndex(index)
        setFeaturedHidden(false)

        // Assuming blogPosts and featuredBlogIndex are stable or their changes should trigger a re-evaluation
        const sortedPosts = [...blogPosts].sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
        const firstPost =
          featuredBlogIndex >= 0 && featuredBlogIndex < sortedPosts.length ? sortedPosts[featuredBlogIndex] : null

        if (firstPost && firstPost.image && firstPost.image.file && firstPost.image.file.url) {
          // Check if it's an image suitable for og:image
          if (!firstPost.image.file.url.includes('.mp4')) {
            // Check if the URL starts with "//" and prepend "https:" to make it protocol-specific
            const imageUrl = firstPost.image.file.url.startsWith('//')
              ? `https:${firstPost.image.file.url}`
              : firstPost.image.file.url
            setPageImage(imageUrl) // Update pageImage state with the corrected URL
          }
          // Optionally, also update pageTitle and pageDescription based on firstPost
          setPageTitle(firstPost.title || pageTitle) // Fallback to existing pageTitle if firstPost.title is not available
          setPageDescription(firstPost.title || pageDescription) // Assuming there's a description; adjust as needed
        }

        scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    // This ensures that any change to blogPosts or featuredBlogIndex triggers re-evaluation
  }, [blogPosts, featuredBlogIndex, pageTitle, pageDescription])

  return (
    <div style={{ width: '100%' }} className={cx('container')}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="description" content={pageTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={pageImage} />
      </Helmet>
      <div className={cx('banner-container')}>
        <div className={cx('banner-img')}>
          <Picture className={cx('g1001-logo')} src={logos[0].file.url} />
        </div>
        <div>
          {subHeaderArray &&
            subHeaderArray.map(function(item, i) {
              return (
                <Text key={i} tag="p" type="b1" className={cx('content-paragraph')}>
                  {item}
                  <br />
                  <br />
                </Text>
              )
            })}
        </div>
      </div>
      <div className={cx('investment-areas')}>
        <div className={cx('focus-area-container')}>
          <GradientText
            tag="h3"
            type="h3"
            colors={[gradientColor1, gradientColor2, gradientColor3]}
            colorStops={[gradientStop1, gradientStop2, gradientStop3]}
          >
            {'Investment Areas of Focus'}
          </GradientText>
        </div>

        <div style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }}>
          <div className="content-paragraph-con" style={{ marginTop: '0px', fontWeight: 'bold' }}>
            <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
              {focusArea1Array[0]}
            </Text>
          </div>
          <div className="content-paragraph-con" style={{ marginTop: '0px' }}>
            <div className={cx('content-paragraph')} style={{ minHeight: '20px' }}>
              <Text tag="p" type="b1">
                {focusArea1Array[1]}
              </Text>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }}>
          <div className="content-paragraph-con" style={{ marginTop: '0px', fontWeight: 'bold' }}>
            <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
              {focusArea2Array[0]}
            </Text>
          </div>
          <div className="content-paragraph-con" style={{ marginTop: '0px' }}>
            <div className={cx('content-paragraph')} style={{ minHeight: '20px' }}>
              <Text tag="p" type="b1">
                {focusArea2Array[1]}
              </Text>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px' }}>
          <div className="content-paragraph-con" style={{ marginTop: '0px', fontWeight: 'bold' }}>
            <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
              {focusArea3Array[0]}
            </Text>
          </div>
          <div className="content-paragraph-con" style={{ marginTop: '0px' }}>
            <div className={cx('content-paragraph')} style={{ minHeight: '20px' }}>
              <Text tag="p" type="b1">
                {focusArea3Array[1]}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('banner-text-center')}>
        <a href={contactEmail}>
          <button className={cx('connect-button')}>CONNECT WITH US</button>
        </a>
      </div>
      <hr style={{ marginTop: '20px' }}></hr>
      <div className={cx('investment-areas')}>
        <div className={cx('focus-area-container')}>
          {blogPosts && !blogPosts.every(post => post.slug === 'testpost') && (
            <GradientText
              tag="h1"
              className={cx('hero-title')}
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
              style={{ marginTop: '10px', marginBottom: '10px', paddingBottom: '10px' }}
            >
              {'Innovations Insights'}
            </GradientText>
          )}
        </div>
        {showTeam == true && (
          <div className={cx('persons-container')}>
            <div className={cx('banner-text-center')}>
              <Text tag="h3" type="h3" className={cx('nowrap', 'our-team-text')}>
                Our Team
              </Text>
            </div>
            <div className={cx('list')}>
              {list &&
                list.map(item => {
                  if (item.displaySection == 'A' && item.image) {
                    return <Person className={cx('person')} key={item.image.url} {...item} />
                  }
                })}
            </div>
          </div>
        )}
        <div style={{ marginTop: '20px' }} ref={scrollToRef}>
          {featuredHidden === false && showFeatured && blogPosts && blogPosts.length > 0 && (
            <div style={{ margin: '20px' }}>
              {(() => {
                const sortedPosts = [...blogPosts].sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
                const firstPost =
                  featuredBlogIndex >= 0 && featuredBlogIndex < sortedPosts.length
                    ? sortedPosts[featuredBlogIndex]
                    : null

                return firstPost ? (
                  <>
                    <div style={{ marginBottom: '40px' }}>
                      <div className={cx('feat-logo-con')}>
                        {firstPost.image && firstPost.image.file.url.includes('.mp4') ? (
                          <video className={cx('feat-logo')} controls>
                            Your browser does not support the &lt;video&gt; tag.
                            <source src={firstPost.image.file.url} />
                          </video>
                        ) : firstPost.vimeoUrl ? (
                          <iframe
                            src={firstPost.vimeoUrl}
                            width={'100%'}
                            height={'400'}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <Picture
                            className={cx('feat-logo')}
                            src={firstPost.image ? firstPost.image.file.url : '/images/ms/g1001-light-gray.png'}
                            alt={firstPost.image ? firstPost.image.description : 'Group 1001'}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: '20px' }} className={cx('featured-area')}>
                      <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
                        {firstPost.publishDate}
                      </Text>
                    </div>
                    <div style={{ marginTop: '20px' }} className={cx('featured-area')}>
                      <GradientText
                        tag="h2"
                        type="h3"
                        style={{ fontSize: '4.5rem', fontWeight: 'bold' }}
                        colors={[gradientColor1, gradientColor2, gradientColor3]}
                        colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                      >
                        {firstPost.title}
                      </GradientText>
                    </div>
                    <div>
                      <div className={cx('collapsible-content', { expanded: isExpanded })}>
                        {processRichTextNodes(firstPost.content.json.content)}
                      </div>
                      <GradientText
                        tag="h2"
                        type="h3"
                        style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                        colors={[gradientColor1, gradientColor2, gradientColor3]}
                        colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                        onClick={toggleExpand}
                        className={cx('show-pointer')}
                      >
                        {isExpanded ? 'Show Less' : 'Show More'}
                      </GradientText>
                    </div>
                  </>
                ) : null
              })()}
            </div>
          )}
        </div>
      </div>
      <div className={cx('blogContainer')}>
        {blogPosts &&
          (blogPosts.length > 1 || showFeatured === false) &&
          blogPosts
            .filter(item => item.slug !== 'testpost')
            .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate)) // Sort by publishDate in descending order
            .map((item, i) => {
              // Check if the current index is the one to skip (only when showing featured)
              if (featuredHidden === false && showFeatured && i === featuredBlogIndex) {
                return null
              }

              // Render your content for each blog post
              return (
                <div key={i} className={cx('blogPost')}>
                  <div>
                    <div className={cx('logo-con')}>
                      {item.image && item.image.file.url.includes('.mp4') ? (
                        <video className={cx('partner-logo')} controls>
                          Your browser does not support the &lt;video&gt; tag.
                          <source src={item.image.file.url} />
                        </video>
                      ) : item.vimeoUrl ? (
                        <div className={cx('partner-logo')}>
                          <iframe
                            src={item.vimeoUrl}
                            width={'310'}
                            height={'200'}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      ) : (
                        <div className={cx('partner-logo')}>
                          <Picture
                            src={item.image ? item.image.file.url : '/images/ms/g1001-light-gray.png'}
                            alt={item.image ? item.image.description : 'Group 1001'}
                          />
                        </div>
                      )}
                    </div>
                    <div className={cx('article-info')}>
                      <Text tag="p" type="b2Medium" className={cx('date')}>
                        {item.publishDate}
                      </Text>
                    </div>
                    <div className={cx('vert-flex')}>
                      <GradientText
                        className={cx('title-con')}
                        tag="h4"
                        type="h4"
                        colors={[gradientColor1, gradientColor2, gradientColor3]}
                        colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                        onClick={() => {
                          if (showFeatured)
                            // New Innovation Article slug URI
                            handleClick(`${window.location.origin}/article/${encodeURIComponent(item.slug)}`)
                        }}
                      >
                        {item.title}
                      </GradientText>
                      <div style={{ marginTop: '0px' }} className={cx('collapsible-content', { expanded: false })}>
                        {processRichTextNodes(item.content.json.content)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

Innovators.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
      name: PropTypes.string,
      title: PropTypes.string,
      company: PropTypes.string,
      experience: PropTypes.string,
      education: PropTypes.string,
      fullTitle: PropTypes.string,
      displaySection: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string,
  subHeader: PropTypes.shape({
    subHeader: PropTypes.string.isRequired,
  }).isRequired,
  focusArea1: PropTypes.string,
  focusArea2: PropTypes.string,
  focusArea3: PropTypes.string,
  footerText: PropTypes.string,
  contactEmail: PropTypes.string,
  showTeam: PropTypes.bool,
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      publishDate: PropTypes.string,
      types: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      content: PropTypes.shape({
        json: PropTypes.object, // Simplified to just ensure it's an object
      }),
      vimeoUrl: PropTypes.string,
      image: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    })
  ),
}

export default Innovators
