import React from 'react'
import { graphql } from 'gatsby'
import Innovators from '@components/Innovators'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'
import styles from './InnovatorsContainer.module.scss'

const cx = classnames.bind(styles)

const InnovatorsContainer = ({
  header,
  subHeader,
  members,
  anchor,
  className,
  logos,
  focusArea1,
  focusArea2,
  focusArea3,
  footerText,
  contactEmail,
  showTeam,
  blogPosts,
}) => (
  <div className={cx('container', className, 'grid', 'tablet-grid')} id={anchor}>
    <Innovators
      className={cx('col-l', 'col-l-8', 'col-l-o-2', 'innovators')}
      title={header}
      subHeader={subHeader}
      list={members}
      logos={logos}
      focusArea1={focusArea1}
      focusArea2={focusArea2}
      focusArea3={focusArea3}
      footerText={footerText}
      contactEmail={contactEmail}
      showTeam={showTeam}
      blogPosts={blogPosts}
    ></Innovators>
  </div>
)

export const query = graphql`
  fragment InnovatorsDataFragment on ContentfulHomeInnovators {
    anchor {
      url
    }
    title: header
    subHeader {
      subHeader
    }
    members {
      image {
        description
        file {
          url
        }
      }
      name
      title
      company
      education {
        education
      }
      fullTitle
      experience {
        experience
      }
      displaySection
    }
    focusArea1 {
      focusArea1
    }
    focusArea2 {
      focusArea2
    }
    focusArea3 {
      focusArea3
    }
    footerText {
      footerText
    }
    contactEmail
    showTeam
    blogPosts {
      slug
      title
      publishDate(formatString: "MMM D, YYYY")
      types {
        title
        slug
      }
      content {
        json
      }
      vimeoUrl
      image {
        title
        description
        file {
          url
          contentType
        }
      }
    }
  }
`

InnovatorsContainer.propTypes = {
  anchor: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.shape({
    subHeader: PropTypes.string.isRequired,
  }).isRequired,
  focusArea1: PropTypes.shape({
    focusArea1: PropTypes.string.isRequired,
  }).isRequired,

  focusArea2: PropTypes.shape({
    focusArea2: PropTypes.string,
  }),

  focusArea3: PropTypes.shape({
    focusArea3: PropTypes.string,
  }),

  footerText: PropTypes.shape({
    footerText: PropTypes.string.isRequired,
  }).isRequired,

  contactEmail: PropTypes.string.isRequired,
  showTeam: PropTypes.bool,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
      }),
      name: PropTypes.string,
      title: PropTypes.string,
      company: PropTypes.string,
      experience: PropTypes.shape({
        experience: PropTypes.string.isRequired,
      }).isRequired,
      education: PropTypes.shape({
        education: PropTypes.string.isRequired,
      }).isRequired,
      fullTitle: PropTypes.string,
      displaySection: PropTypes.string,
    })
  ),
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      publishDate: PropTypes.string,
      types: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      content: PropTypes.shape({
        json: PropTypes.shape({
          nodeType: PropTypes.string,
          data: PropTypes.object,
          content: PropTypes.arrayOf(
            PropTypes.shape({
              nodeType: PropTypes.string,
              data: PropTypes.object,
              content: PropTypes.arrayOf(
                PropTypes.shape({
                  nodeType: PropTypes.string,
                  value: PropTypes.string,
                  marks: PropTypes.arrayOf(PropTypes.object), // If marks are important
                  // Include other relevant fields if needed
                })
              ),
            })
          ),
        }),
      }),
      vimeoUrl: PropTypes.string,
      image: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    })
  ),
}

export default InnovatorsContainer
