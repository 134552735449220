import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './InnovationsPage.module.scss'
import InnovatorsContainer from '../IndexPage/InnovatorsContainer'
import { parseImage, parseAnchor } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from '@components/Layout'

const cx = classnames.bind(styles)

const InnovationsPage = ({ data }) => {
  const { contentfulHomeInnovators } = data
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  let logos = populateLogos(contentfulHomeInnovators)

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={contentfulHomeInnovators.header}
            smoothScroll={true}
          >
            <InnovatorsContainer
              members={contentfulHomeInnovators.members.map(item => ({
                ...item,
                image: item.image && parseImage(item.image),
              }))}
              header={contentfulHomeInnovators.header}
              subHeader={contentfulHomeInnovators.subHeader}
              anchor={parseAnchor(contentfulHomeInnovators.anchor.url)}
              logos={logos}
              focusArea1={contentfulHomeInnovators.focusArea1}
              focusArea2={contentfulHomeInnovators.focusArea2}
              focusArea3={contentfulHomeInnovators.focusArea3}
              footerText={contentfulHomeInnovators.footerText}
              contactEmail={contentfulHomeInnovators.contactEmail}
              showTeam={contentfulHomeInnovators.showTeam}
              blogPosts={contentfulHomeInnovators.blogPosts}
            />
            <div className={cx('useless')}></div> {/*this div is just there so eslint stays happy*/}
          </Layout>
        )
      }}
    </TransitionState>
  )
}

function populateLogos(contentfulHomeInnovators) {
  let logos = []
  logos.push(contentfulHomeInnovators.logoA)
  return logos
}

InnovationsPage.propTypes = {
  data: PropTypes.object,
}

export default InnovationsPage
