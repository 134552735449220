import InnovationsPage from '@components/InnovationsPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulHomeInnovators {
      anchor {
        url
      }
      header
      subHeader {
        subHeader
      }
      members {
        image {
          description
          file {
            url
          }
        }
        name
        title
        company
        education {
          education
        }
        fullTitle
        experience {
          experience
        }
        displaySection
      }
      logoA {
        description
        file {
          url
        }
      }
      focusArea1 {
        focusArea1
      }
      focusArea2 {
        focusArea2
      }
      focusArea3 {
        focusArea3
      }
      footerText {
        footerText
      }
      contactEmail
      showTeam
      blogPosts {
        slug
        title
        publishDate(formatString: "MMM D, YYYY")
        types {
          title
          slug
        }
        content {
          json
        }
        vimeoUrl
        image {
          title
          description
          file {
            url
            contentType
          }
        }
      }
    }
  }
`

export default InnovationsPage