import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Picture from '@components/Picture'
import style from './Person.module.scss'
import classnames from 'classnames/bind'
import Text from '@components/Text'
import Modal from '../../Modal'

const cx = classnames.bind(style)

const Person = ({ image, name, title, company, experience, education, fullTitle, className }) => {
  const [showModal, setShowModal] = useState(false)

  var expArray, educationArray, fullTitleArray
  if (experience) {
    expArray = experience.experience.split('|')
  }
  if (education) {
    educationArray = education.education.split('|')
  }
  if (fullTitle) {
    fullTitleArray = fullTitle.split('|')
  }

  return (
    <div
      className={cx(['container', className])}
      onClick={() => {
        setShowModal(true)
      }}
    >
      <div className={cx('circle-container')}>
        <Picture
          className={cx('picture-circle')}
          src={image.url}
          alt={image.description}
          maxWidth="260"
          maxHeight="260"
        />
      </div>
      <div className={cx('colored-circle')}></div>

      <Text tag="p" type="b2Medium">
        {name}
      </Text>
      <Text tag="p" type="fineprint1" className={cx('role')}>
        {title}
      </Text>
      <Text tag="p" type="fineprint2">
        {company}
      </Text>

      {showModal && (
        <Modal>
          <div
            className={cx('modal-background')}
            onClick={e => {
              setShowModal(false)
              e.stopPropagation()
            }}
          >
            <div
              className={cx('modal-container')}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                className={cx('x-to-close-modal')}
                onClick={e => {
                  setShowModal(false)
                  e.stopPropagation()
                }}
              >
                <Text tag="p" type="h4">
                  X
                </Text>
              </div>

              <div>
                <Picture className={cx('picture-modal')} src={image.url} alt={image.description} />
              </div>
              <div className={cx('bio-container')}>
                <div>
                  <Text tag="p" type="h3">
                    {name}
                  </Text>
                </div>
                <Picture
                  className={cx('orange-underline')}
                  src={'/images/ms/orangeUnderline-40x2.png'}
                  alt="underline"
                />
                <div className={cx('full-title')}>
                  {fullTitleArray &&
                    fullTitleArray.map(function(item, i) {
                      return (
                        <Text key={i} tag="p" type="b2" className={cx('title-snippet')}>
                          {item}
                        </Text>
                      )
                    })}
                </div>
                <Text tag="p" type="b1Bold" className={cx('section-title')}>
                  Education:
                </Text>
                <div className={cx('education')}>
                  {educationArray &&
                    educationArray.map(function(item, i) {
                      return (
                        <Text key={i} tag="p" type="b2" className={cx('education-snippet')}>
                          {item}
                        </Text>
                      )
                    })}
                </div>
                <Text tag="p" type="b1Bold" className={cx('section-title')}>
                  Experience:
                </Text>
                <div>
                  {expArray &&
                    expArray.map(function(item, i) {
                      return (
                        <Text key={i} tag="p" type="b2" className={cx('exp-snippet')}>
                          {item}
                        </Text>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

const imageShape = {
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
}

Person.propTypes = {
  image: PropTypes.shape(imageShape).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  education: PropTypes.string.isRequired,
  fullTitle: PropTypes.string.isRequired,
  displaySection: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Person
